var firebaseConfig = {
    apiKey: "AIzaSyDEdtKQ3JjXqYtRaM1kTDJs1a2FdeSTuGk",
    authDomain: "todomarcinek.firebaseapp.com",
    projectId: "todomarcinek",
    storageBucket: "todomarcinek.appspot.com",
    messagingSenderId: "727757108651",
    appId: "1:727757108651:web:8bb67943b5ec11fb9b8eee"
};

exports.firebaseConfig = firebaseConfig;
